<template>
  <base-selector-autocomplete
    :items="items"
    :initial-value="initialValue"
    item-text="full_name"
    item-value="_id"
    label="Select credentials"
    @select="newSelect => $emit('select', newSelect, 'credentials')"
  />
</template>

<script>
  import BaseSelectorAutocomplete from '@/components/base/selectors/BaseSelectorAutocomplete';
  import { mapGetters } from 'vuex';

  export default {
    name: 'BaseCredentialsSelector',
    components: {
      BaseSelectorAutocomplete,
    },
    props: {
      initialValue: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      ...mapGetters({
        credentials: 'credentials/noStatsCredentials',
      }),
      items () {
        const items = [{
          full_name: 'all users',
          _id: '',
        }];
        this.credentials.map(cred => {
          items.push({
            full_name: `${cred.first_name} ${cred.last_name} ${cred.username} ${cred._id}`,
            _id: cred._id,
          });
        });
        return items;
      },
    },
  };
</script>

<style scoped>

</style>
