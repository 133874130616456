<template>
  <v-autocomplete
    v-model="select"
    :items="items"
    :item-text="itemText"
    item-value="_id"
    :label="label"
    persistent-hint
    return-object
    single-line
  />
</template>

<script>
  import { selectorsMixin } from '@/mixins/selectors/selectorsMixin';

  export default {
    name: 'BaseSelectorAutocomplete',
    mixins: [selectorsMixin],
    props: {
      initialValue: {
        type: Object,
        default: () => null,
      },
      itemText: {
        type: String,
        default: 'full_name',
      },
      itemValue: {
        type: String,
        default: '_id',
      },
    },
  };
</script>

<style scoped>

</style>
